@import "./media.scss";

$border-color: #BDBDBE;
$grey: #C1C1C1;

$fg-primary: #37253F;
$fg-secondary: #16B47A;
$fg-tertiary: #16B47A;

$fg-highlight: #16B47A;

$bg-primary: #FFFFFF;
$bg-secondary: $fg-secondary;
$bg-tertiary: $fg-tertiary;

$fg-placeholder: $border-color;

$font-primary: "Proxima Nova";
$font-secondary: "Poppins";

$z-index-base: 10000;
$z-index-modal: $z-index-base + 10;
$z-index-popup: $z-index-modal + 10;
$z-index-loader: $z-index-popup + 10;
$z-index-overlay: $z-index-loader + 10;
$z-index-error: $z-index-overlay + 10;

$gutter-sm: 15px;
$gutter-md: 30px;
$gutter-vert: 10px;

$box-padding: 12.5px;
$modal-padding: 30px;
$modal-content-cap: 466px;

@mixin modal-padding {
    padding: 15px;
    @include media(">=md") {
        padding: 15px 30px;
    }
}

@mixin no-scroll {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
        -webkit-appearance: none;
    }
    
    &::-webkit-scrollbar:vertical {
        display: none;
        width: 0;
    }
    
    &::-webkit-scrollbar:horizontal {
        display: none;
        height: 0;
    }
    
    &::-webkit-scrollbar-track { 
        background-color: transparent; 
    }
}

@mixin scroll {
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        -webkit-appearance: none;
    }
    
    &::-webkit-scrollbar:vertical {
        width: 5px;
    }
    
    &::-webkit-scrollbar:horizontal {
        height: 5px;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: #ccc;
        border-radius: 10px;
        // border: 2px solid #eee;
    }
    
    &::-webkit-scrollbar-track { 
        background-color: transparent; 
    }
}

@mixin input-box($include-borders: true, $include-padding: true, $include-shadow: false, $focused: false) {
    font-size: 30px;
    line-height: 24px;
    outline: none;
    @if $include-borders == true {
        box-sizing: border-box;
        @if $focused == true {
            border-bottom: 1px solid $bg-secondary;
        }
        @if $focused == false {
            border-bottom: 1px solid $border-color;
        }
        // border-radius: 4px;
    }
    @if $include-padding == true {
        padding: $box-padding 0;
    }

    @if $include-shadow == true {
        box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2),
                    0px 8px 10px 1px rgba(0,0,0,0.14),
                    0px 3px 14px 2px rgba(0,0,0,0.12);
    }
}

@mixin input-label {
    @include font-label;
    padding-bottom: 4.5px;
}

@mixin font-base {
    font-family: $font-primary;
    color: $fg-primary;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0;
}

@mixin font-bold {
    font-family: $font-primary;
    color: $fg-primary;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0;
    font-weight: bold;
}

@mixin font-subscript {
    @include font-base;
    font-size: 10px;
    line-height: 17px;
}

@mixin font-label {
    @include font-base;
    font-family: $font-secondary;
    font-weight: bold;
    font-size: 16px;
}

@mixin font-title($secondary: false) {
    @include font-base;
    @if ($secondary == true) {
        font-family: $font-secondary;
    }
    @if ($secondary == false) {
        font-family: $font-primary;
    }
    font-weight: bold;
    font-size: 21px;
    line-height: 28px;
}

@mixin font-title-big {
    @include font-base;
    font-family: $font-secondary;
    font-weight: bold;
    
    font-size: 21px;
    line-height: 28px;

    @include media(">=md") {
        font-size: 28px;
        line-height: 38px; 
    }
}

@mixin emoji($size: large) {

    @if ($size == large) {
        $emoji-size: 50px;
        min-width: $emoji-size;
        & img {
            width: $emoji-size;
        }

        @include media(">=md") {
            $emoji-size: 60px;
            min-width: $emoji-size;

            & img {
                width: $emoji-size;
            }
        }

        @include media(">=2c") {
            $emoji-size: 75px;
            min-width: $emoji-size;

            & img {
                width: $emoji-size;
            }
        }
    }
}

@mixin with-side-pane($type: 'child') {
    @if $type == 'child' {
        :global(.with-side-pane) & {
            @content
        }
    } @else if $type == 'canonical' {
        &:global(.with-side-pane) {
            @content
        }
    }
}

@mixin with-cap-width {
    @include media(">=md") {
        :global(.cap-width) & {
            @content
        }
    }
}